import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import './Retraitements.css';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import CalculateurCotisationsForm from '../../../calculateurCotisationsForm/CalculateurCotisationsForm.js';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton.js';
import Cursor from '../../../../atoms/icons/parcours/cursor/Cursor.js';
import {
  formatNumberWithSpaces,
  formatObjectForPosting,
  getTotalFromValues,
  removeSpaces,
} from '../../../../../utils/Utils.js';
import MultiplesInputs, {
  calcTotalFormMultipleInputs,
  fillMultipleInputs,
  haveMultiplesInputsErrors,
  removeSpacesFromMultiplesInputs,
} from '../../../../molecules/formComponents/multiplesInputs/MultiplesInputs.js';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto.js';

function Retraitements({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    unregister,
    getValues,
    formState: { errors },
  } = useForm();
  const Form = { watch, setValue, register };
  const {
    showTooltip,
    estimationValues,
    moveToNextStep,
    parcoursTopRef,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    getStepToGo,
    setGoToStep,
    fillInputsFromEstimationValues,
    typesCommerce,
  } = useContext(ParcoursContext);
  const anchorRef = useRef(null);
  const [calculateurVisible, setCalculateurVisible] = useState(false);
  const [autresCharges, setAutresCharges] = useState([{ index: 0, visible: true, error: '' }]);
  const [autresMajorations, setAutresMajorations] = useState([{ index: 0, visible: true, error: '' }]);
  const [autresRemunerations, setAutresRemunerations] = useState([{ index: 0, visible: true, error: '' }]);
  const inputsWatcher = watch([
    'production_prestations_vendues',
    'valeur_ajoutee',
    'marge_brute_globale',
    'excedent_brut_exploitation',
    'resultat_exercice',
    'total_retraitements',
    'total_commissions_nettes',
    'ebe_retraite',
  ]);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'retraitements_remunerations',
      [
        'remunerations_dirigeants',
        'cotisations_sociales_dirigeants',
        'remontees_holding_dirigeant',
        'total_retraitements_remunerations',
      ],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'retraitements_autres_charges',
      ['total_retraitements_autres_charges', 'note_retraitements_autres_charges'],
      setValue,
      estimationValues,
    );
    setValue('note_rentabilite_retraitee', estimationValues?.rentabilite_retraitee?.note_rentabilite_retraitee);
    fillInputsFromEstimationValues(
      'compte_resultat',
      [
        'production_prestations_vendues',
        'valeur_ajoutee',
        'excedent_brut_exploitation',
        'resultat_exercice',
        'total_commissions_nettes',
      ],
      setValue,
      estimationValues,
    );
    setValue('marge_brute_globale', estimationValues?.detail_marge?.marge_brute_globale);

    setValue('note_detail_marge', estimationValues?.detail_marge?.note_detail_marge);

    fillMultipleInputs(
      estimationValues?.retraitements_remunerations?.autres,
      setValue,
      setAutresRemunerations,
      'remunerations',
    );
    fillMultipleInputs(estimationValues?.retraitements_autres_charges?.autres, setValue, setAutresCharges, 'charges');
    fillMultipleInputs(
      estimationValues?.retraitements_autres_charges?.autres_majorations,
      setValue,
      setAutresMajorations,
      'autres_majorations',
    );
  }, [estimationValues]);

  useEffect(() => {
    scrollForCalculateur();
  }, [calculateurVisible]);

  async function formSubmit(values) {
    if (haveMultiplesInputsErrors(autresCharges)) return;
    if (haveMultiplesInputsErrors(autresMajorations)) return;
    if (haveMultiplesInputsErrors(autresRemunerations)) return;

    const formattedValues = formatObjectForPosting(values);

    const tmp = {
      retraitements_remunerations: {
        remunerations_dirigeants: formattedValues.remunerations_dirigeants,
        cotisations_sociales_dirigeants: formattedValues.cotisations_sociales_dirigeants,
        autres_majorations: removeSpacesFromMultiplesInputs(values.autres_majorations),
        remontees_holding_dirigeant: formattedValues.remontees_holding_dirigeant,
        total_retraitements_remunerations: formattedValues.total_retraitements_remunerations,
        autres: removeSpacesFromMultiplesInputs(values.remunerations),
      },
      retraitements_autres_charges: {
        total_retraitements_autres_charges: formattedValues.total_retraitements_autres_charges,
        autres: removeSpacesFromMultiplesInputs(values.charges),
      },
      rentabilite_retraitee: {
        ebe_retraite: formattedValues.ebe_retraite,
        total_retraitements:
          formattedValues.total_retraitements_remunerations + formattedValues.total_retraitements_autres_charges,
      },
    };

    if (!secondaryParcours) {
      tmp.retraitements_autres_charges.note_retraitements_autres_charges = values.note_retraitements_autres_charges;
      tmp.rentabilite_retraitee.note_rentabilite_retraitee = values.note_rentabilite_retraitee;
    }

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  function scrollForCalculateur() {
    if (step != 7) return;

    if (calculateurVisible) parcoursTopRef?.current.scrollIntoView({ behavior: 'smooth' });
    else
      setTimeout(() => {
        anchorRef?.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
  }

  function setParcoursValues(salaire, charges) {
    setValue('remunerations_dirigeants', salaire);
    setValue('cotisations_sociales_dirigeants', charges);
    calcTotalRetraitementsRemunerations();
  }

  //calcul auto des inputs
  function calcTotalRetraitementsRemunerations() {
    const tmp = getValues([
      'remunerations_dirigeants',
      'cotisations_sociales_dirigeants',
      'remontees_holding_dirigeant',
    ]);
    const remunerations = getValues('remunerations');

    setValue(
      'total_retraitements_remunerations',
      removeSpaces(getTotalFromValues(tmp)) + calcTotalFormMultipleInputs(remunerations, autresRemunerations) + '',
    );

    calcTotalRetraitements();
  }

  function calcTotalRetraitementsAutresCharges() {
    const charges = getValues('charges');
    const majorations = getValues('autres_majorations');

    setValue(
      'total_retraitements_autres_charges',
      removeSpaces(calcTotalFormMultipleInputs(charges, autresCharges)) -
        removeSpaces(calcTotalFormMultipleInputs(majorations, autresMajorations)),
    );

    calcTotalRetraitements();
  }

  function calcTotalRetraitements() {
    const retraitementsAutresCharges = getValues('total_retraitements_autres_charges');
    const retraitementsRemunerations = getValues('total_retraitements_remunerations');

    setValue(
      'total_retraitements',
      removeSpaces(retraitementsAutresCharges) + removeSpaces(retraitementsRemunerations),
    );

    calcEbeRetraite();
  }

  function calcEbeRetraite() {
    const tmp = getValues(['excedent_brut_exploitation', 'total_retraitements', 'total_commissions_nettes']);

    const value1 = parseInt(removeSpaces(tmp[0]), 10);
    const value2 = parseInt(removeSpaces(tmp[1]), 10);
    const value3 = typesCommerce.includes(2) ? parseInt(removeSpaces(tmp[2]), 10) : 0;

    const total = value1 + value2 + value3;

    setValue('ebe_retraite', total);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <NumberInput
        validation={{}}
        className='d-none'
        type='hidden'
        name='production_prestations_vendues'
        disabled
        useForm={Form}
      />
      {typesCommerce.includes(2) && (
        <NumberInput
          validation={{}}
          className='d-none'
          type='hidden'
          name='total_commissions_nettes'
          disabled
          useForm={Form}
        />
      )}
      <NumberInput validation={{}} className='d-none' type='hidden' name='valeur_ajoutee' disabled useForm={Form} />
      <NumberInput
        validation={{}}
        className='d-none'
        type='hidden'
        name='marge_brute_globale'
        disabled
        useForm={Form}
      />
      <NumberInput
        validation={{}}
        className='d-none'
        type='hidden'
        name='excedent_brut_exploitation'
        disabled
        useForm={Form}
      />
      <NumberInput
        validation={{}}
        className='d-none'
        type='hidden'
        name='resultat_exploitation'
        disabled
        useForm={Form}
      />
      <NumberInput
        validation={{}}
        className='d-none'
        type='hidden'
        name='total_retraitements'
        disabled
        useForm={Form}
      />
      <NumberInput validation={{}} className='d-none' type='hidden' name='ebe_retraite' disabled useForm={Form} />

      <CalculateurCotisationsForm
        showTooltip={() => showTooltip('step_7_tuto_2.webp')}
        visible={calculateurVisible}
        setVisible={setCalculateurVisible}
        setParcoursValues={setParcoursValues}
        className={calculateurVisible ? 'mb-md' : ''}
      />
      <p className='warning-parcours'>Renseigner cette étape est primordial pour une bonne évaluation</p>
      <p className='text-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>retraitements des rémunérations</h2>
        <div className='row-1000 mb-md'>
          <FilledButton
            bgColor='var(--main-color)'
            className='retraitement-button'
            onClick={() => showTooltip('step_7_tuto_1.webp')}>
            Comment bien retraiter vos charges pour obtenir une meilleure estimation&nbsp;?
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
          <FilledButton
            bgColor='var(--secondary-color)'
            className='retraitement-button'
            onClick={() => setCalculateurVisible(!calculateurVisible)}>
            Calculez automatiquement vos charges sociales depuis le salaire net ou brut
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
        </div>
        <div className='row-1000' ref={anchorRef}>
          <NumberInput
            onChange={calcTotalRetraitementsRemunerations}
            useForm={Form}
            label={
              <>
                rémunérations annuelles du (des) dirigeant(s) <Tuto onClick={() => showTooltip('step_7_tuto_3.webp')} />
              </>
            }
            tip='Et des salariés à ne pas conserver'
            name='remunerations_dirigeants'
            error={errors?.remunerations_dirigeants && errors.remunerations_dirigeants.message}
            icon='euro'
            onKeyDown={e => {
              if (e.keyCode === 9 && e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                setStep(index - 1);
              }
            }}
          />
          <NumberInput
            onChange={calcTotalRetraitementsRemunerations}
            useForm={Form}
            label={
              <>
                cotisations sociales annuelles du (des) dirigeant(s)
                <Tuto onClick={() => showTooltip('step_7_tuto_4.webp')} />
              </>
            }
            tip='Et des salariés à ne pas conserver'
            name='cotisations_sociales_dirigeants'
            error={errors?.cotisations_sociales_dirigeants && errors.cotisations_sociales_dirigeants.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            onChange={calcTotalRetraitementsRemunerations}
            useForm={Form}
            label={
              <>
                remontées holding dirigeant(s)
                <Tuto onClick={() => showTooltip('step_7_tuto_5.webp')} />
              </>
            }
            tip='Et des salariés à ne pas conserver'
            name='remontees_holding_dirigeant'
            error={errors?.remontees_holding_dirigeant && errors.remontees_holding_dirigeant.message}
            icon='euro'
          />
        </div>
        <MultiplesInputs
          label={
            <>
              Autres retraitements de rémunérations
              <Tuto onClick={() => showTooltip('step_7_tuto_6.webp')} />
            </>
          }
          secondaryParcours={secondaryParcours}
          onInputChange={calcTotalRetraitementsRemunerations}
          name='remunerations'
          values={autresRemunerations}
          setValues={setAutresRemunerations}
          unregister={unregister}
          Form={Form}
        />
        <NumberInput
          bgColor='var(--main-color)'
          useForm={Form}
          validation={{}}
          label='Total des retraitements des rémunérations'
          disabled
          name='total_retraitements_remunerations'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>Retraitements des autres charges</h2>
        <div className='multiple-inputs-container'>
          <MultiplesInputs
            label={
              <>
                Retraitements des autres charges
                <Tuto onClick={() => showTooltip('step_7_tuto_7.webp')} />
              </>
            }
            secondaryParcours={secondaryParcours}
            name='charges'
            values={autresCharges}
            setValues={setAutresCharges}
            unregister={unregister}
            onInputChange={calcTotalRetraitementsAutresCharges}
            Form={Form}
          />
        </div>
        <div className='multiple-inputs-container'>
          <MultiplesInputs
            label={
              <>
                majoration des charges externes
                <Tuto onClick={() => showTooltip('step_7_tuto_8.webp')} />
              </>
            }
            secondaryParcours={secondaryParcours}
            name='autres_majorations'
            values={autresMajorations}
            setValues={setAutresMajorations}
            unregister={unregister}
            onInputChange={() => {
              calcTotalRetraitementsRemunerations();
              calcTotalRetraitementsAutresCharges();
            }}
            Form={Form}
          />
        </div>
        <NumberInput
          useForm={Form}
          validation={{}}
          label='Total des retraitements des autres charges'
          bgColor='var(--main-color)'
          disabled
          name='total_retraitements_autres_charges'
        />
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_retraitements_autres_charges'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_retraitements_autres_charges && errors.note_retraitements_autres_charges.message}
          />
        )}
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='mb-3'>Récapitulatif de la rentabilité retraitée</h2>
        <div className='recap-ebe'>
          <div className='recap-row'>
            <p>Produits d'exploitation</p>
            <p>{formatNumberWithSpaces(inputsWatcher[0])}€</p>
          </div>
          <div className='recap-row'>
            <p>Marge brute globale</p>
            <p>{formatNumberWithSpaces(inputsWatcher[2])}€</p>
          </div>
          <div className='recap-row'>
            <p>Valeur ajoutée</p>
            <p>{formatNumberWithSpaces(inputsWatcher[1])}€</p>
          </div>
          <div className='recap-row'>
            <p>Excédent brut d'exploitation</p>
            <p>{formatNumberWithSpaces(inputsWatcher[3])}€</p>
          </div>
          <div className='recap-row'>
            <p>Résultat net</p>
            <p>{formatNumberWithSpaces(inputsWatcher[4])}€</p>
          </div>
          <div className='recap-row'>
            <p>Retraitements</p>
            <p>{formatNumberWithSpaces(inputsWatcher[5])}€</p>
          </div>
          {typesCommerce.includes(2) && (
            <div className='recap-row'>
              <p>Total des comissions nettes</p>
              <p>{formatNumberWithSpaces(inputsWatcher[6])}€</p>
            </div>
          )}
          <div className='recap-row recap-last-row'>
            <p>EBE RETRAITÉ</p>
            <p>{formatNumberWithSpaces(inputsWatcher[7])}€</p>
          </div>
        </div>{' '}
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_rentabilite_retraitee'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_rentabilite_retraitee && errors.note_rentabilite_retraitee.message}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default Retraitements;
